<template lang="">
  <div>
    <div v-for="(item, index) in rawData" :key="`repair-log-item-${index}`">
      <div
        class="px-5 py-3 my-2 rounded-lg bg-oWhite"
        style=" box-shadow: 5px 5px 20px 5px  #31346915,-5px -5px 20px 5px #31346900;"
      >
        <p class="font-medium text-base">
          {{ item._source.repair_title || '--' }}
        </p>
        <div class="text-sm ">
          <div class="flex justify-between mt-2">
            <p class="text-gray-500">Repaired By</p>
            <p class="text-gray-800 font-medium">
              {{ item._source.repairman_email }}
            </p>
          </div>
          <div class="flex justify-between mt-2">
            <p class="text-gray-500">Updated At</p>
            <p class="text-gray-800 font-medium">
              {{
                getFormattedDateTime(
                  item._source.created_at,
                  'D MMM YYYY, h:mm:ss a'
                )
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFormattedDateTime } from '@/utils/datetime'
export default {
  props: {
    rawData: {
      type: Array,
    },
  },
  methods: {
    getFormattedDateTime,
  },
}
</script>
<style lang=""></style>
